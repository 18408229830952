// BotsList.js
import React, { useEffect, useState } from 'react';
import './styles/botsList.css';
import Modal from '../components/modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BotsList = ({ bots }) => {
    const statusMap = {
        ACTIVE: "Активен",
        WAITING_FOR_START: "Ожидание запуска",
        UNAUTHORIZED: "Не авторизован",
        PENDING_PROXY: "Ожидает прокси"
    };
    

    function getCurrentDateTimeWithYearOffset(yearsOffset) {
        const now = new Date(); // текущая дата и время
        const futureDate = new Date(now.getFullYear() + yearsOffset, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()); // добавляем годы к текущей дате
        
        // форматирование в ISO 8601
        const isoString = futureDate.toISOString();
    
        // возвращаем нужный формат
        return isoString.substring(0, 23) + '+00:00'; // обрезаем миллисекунды и добавляем часовой пояс
    }

const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedBot, setSelectedBot] = useState(null);
    const [botDetails, setBotDetails] = useState({ username: '', password: '' });
    const [cookies, setCookies] = useState([]);
    const [proxy, setProxy] = useState(null);
    const[authCookieValue, setAuthCookieValue] = useState("")

    const navigate = useNavigate()

    const handleView = (bot) => {
        bot.botCookieList.map(c=>{
            if(c.cookieName === 'auth-token'){
                setAuthCookieValue(c.cookieValue)
            }
        })
        setSelectedBot(bot);
        setBotDetails({ username: bot.username, password: bot.password });
        // Здесь можно сделать запрос на сервер для получения куки и прокси, но для примера используем статические данные
        setProxy(bot.botProxy);
        setCookies(bot.botCookieList);
        setShowViewModal(true);
    };

    const handleDelete = (bot) => {
        setSelectedBot(bot);
        setShowDeleteModal(true);
    };

    const handleClose = () => {
        setShowViewModal(false);
        setShowDeleteModal(false);
        setSelectedBot(null);
        setBotDetails({ username: '', password: '' });
        setProxy(null);
        setCookies([]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBotDetails({ ...botDetails, [name]: value });
    };

    const handleCookieChange = (value) => {
        setAuthCookieValue(value);
    };

    const handleSave = () => {
        console.log(`Bot details saved: ${JSON.stringify(botDetails)}`);
        console.log(`Cookies saved: ${JSON.stringify(cookies)}`);
        let updateBot = {
            username: botDetails.username,
            password: botDetails.password,
            authCookie: {
                cookieName: "auth-token",
                cookieValue: authCookieValue,
                domain: ".twitch.tv",
                expires: getCurrentDateTimeWithYearOffset(1),
                secure: true,
                path: "/",
                httpOnly: false
            }
        }

        axios.patch("https://vzakat.ru/backend/api/bots/"+selectedBot.id, updateBot).then(res=>{
            if(res.status===200){
                setShowViewModal(false);
                setSelectedBot(null);
                navigate(0)
            }
        })
    };

    const handleConfirmDelete = () => {
        console.log(`Bot ${selectedBot.username} deleted`);
        axios.delete("https://vzakat.ru/backend/api/bots/"+selectedBot.id).then(res=>{
            if(res.status===200){
                navigate(0)
            }
        })
    };

    useEffect(()=>{

    }, [authCookieValue])

    return (
        <div className="user-list">
            {bots.map(bot => (
                <div key={bot.id} className="user-item">
                    <span>{bot.username}</span>
                    <span className={`status ${bot.botStatus.toLowerCase()}`}>
                        {statusMap[bot.botStatus] || "Неизвестный статус"}
                    </span>

                    <div className="user-actions">
                        <button className="view" onClick={() => handleView(bot)}>Просмотр</button>
                        <button className="delete" onClick={() => handleDelete(bot)}>Удалить</button>
                    </div>
                </div>
            ))}
            <Modal show={showViewModal} onClose={handleClose}>
                <div className="cookie-item">
                        <h3>Логин и пароль</h3>
                        <div>
                            <label>Логин:</label>
                            <input
                                type="text"
                                name="username"
                                value={botDetails.username}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label>Пароль:</label>
                            <input
                                type="password"
                                name="password"
                                value={botDetails.password}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                {proxy && (<div>
                    <div className="proxy-info cookie-item">
                            <h3>Информация о прокси</h3>
                            <div>
                                <strong>Proxy ID:</strong> {proxy.proxy_id}
                            </div>
                            <div>
                                <strong>IP:</strong> {proxy.ip}
                            </div>
                            <div>
                                <strong>Port:</strong> {proxy.port}
                            </div>
                            <div>
                                <strong>Username:</strong> {proxy.username}
                            </div>
                            <div>
                                <strong>Password:</strong> {proxy.password}
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <h3>Cookies</h3>
                    {[
                        ...cookies.filter(cookie => cookie.cookieName === 'auth-token'),
                        ...cookies.filter(cookie => cookie.cookieName !== 'auth-token'),
                    ].map(cookie => (
                        <div key={cookie.id} className="cookie-item">
                            <div className="cookie-row">
                                <div><strong>Cookie Name:</strong> {cookie.cookieName}</div>
                                <div><strong>Cookie Value:</strong> 
                                    {cookie.cookieName === 'auth-token' ? (
                                        <input
                                            type="text"
                                            value={authCookieValue}
                                            onChange={(e) => handleCookieChange(e.target.value)}
                                        />
                                    ) : (
                                        <span>{cookie.cookieValue}</span>
                                    )}
                                </div>
                                <div><strong>Domain:</strong> {cookie.domain}</div>
                                <div><strong>Path:</strong> {cookie.path}</div>
                                <div><strong>Expires:</strong> {cookie.expires}</div>
                                <div><strong>HttpOnly:</strong> {cookie.httpOnly ? 'Yes' : 'No'}</div>
                                <div><strong>Secure:</strong> {cookie.secure ? 'Yes' : 'No'}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="save-button" onClick={handleSave}>Сохранить</button>
            </Modal>
            <Modal show={showDeleteModal} onClose={handleClose}>
                <div>Вы уверены, что хотите удалить {selectedBot && selectedBot.username}?</div>
                <div className="modal-actions">
                    <button className="confirm-delete" onClick={handleConfirmDelete}>Да</button>
                    <button className="cancel-delete" onClick={handleClose}>Нет</button>
                </div>
            </Modal>
        </div>
    );
};
export default BotsList;
import { useEffect, useState } from "react";
import Header from "../components/header";
import ProxyList from "../components/proxyList";
import Modal from "../components/modal";
import axios from "axios";
import './styles/proxy.css';
import { useNavigate } from "react-router-dom";

const Proxy = ({}) => {
    const navigate = useNavigate()
    const [proxys, setProxys] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newProxy, setNewProxy] = useState({
        ip: '',
        port: '',
        username: '',
        password: ''
    });

    useEffect(() => {
        if (proxys === null) {
            axios.get('https://vzakat.ru/backend/api/proxy').then(res => {
                if (res.status === 200) {
                    setProxys(res.data);
                }
            });
        }
    }, [proxys]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProxy(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddProxy = () => {
        axios.post('https://vzakat.ru/backend/api/proxy', newProxy).then(res => {
            if (res.status === 200) {
                navigate(0)
            }
        });
    };

    if(proxys!==null){
    return (
        <div>
            <button className="add-button" onClick={() => setShowModal(true)}>Добавить</button>
            <ProxyList proxys={proxys} />
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <div className="modal-form">
                    <label>IP:</label>
                    <input
                        type="text"
                        name="ip"
                        value={newProxy.ip}
                        onChange={handleInputChange}
                    />
                    <label>Port:</label>
                    <input
                        type="number"
                        name="port"
                        value={newProxy.port}
                        onChange={handleInputChange}
                    />
                    <label>Username:</label>
                    <input
                        type="text"
                        name="username"
                        value={newProxy.username}
                        onChange={handleInputChange}
                    />
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={newProxy.password}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleAddProxy}>Сохранить</button>
                </div>
            </Modal>
        </div>
    );
}
};

export default Proxy;

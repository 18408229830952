import Header from "../components/header";
import { useEffect, useState } from "react";
import ChangeModal from "../components/change_modal";
import "./styles/main.css";
import axios from "axios";
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

const MainPage = ({}) => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [channel, setChannel] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newChannelName, setNewChannelName] = useState('');
    const [automationEnabled, setAutomationEnabled] = useState(false);
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState(0);
    const [botsFetch, setBotsFetch] = useState(false)

    const handleSendMessage = () => {
        if (message.trim() === '') return;

        if(selectedBot == 0){
          axios.post("https://vzakat.ru/backend/api/chat/sendAllMessage", {
              message: message,
              botId: selectedBot
          }).then(res => {
              if (res.status === 200) {
                  setMessage('');
              }
          });
        }
        else{
          axios.post("https://vzakat.ru/backend/api/chat", {
            message: message,
            botId: selectedBot
        }).then(res => {
            if (res.status === 200) {
                setMessage('');
            }
        });
        }
    };

    const handleChangeChannel = () => {
        setShowModal(true);
    };

    const handleSaveChannel = () => {
        axios.post("https://vzakat.ru/backend/api/chat/changeChannel", {
            channel: newChannelName
        }).then(res=>{
            if(res.status===200){
                setChannel(newChannelName);
                setShowModal(false);
            }
        })
    };

    const handleToggleAutomation = () => {
        setAutomationEnabled(!automationEnabled);
        axios.post("https://vzakat.ru/backend/api/chat/switchAuto");
    };

    const handleBotChange = (event) => {
        setSelectedBot(event.target.value);
    };

    useEffect(() => {
        if (channel === null) {
            axios.get("https://vzakat.ru/backend/api/chat/getCurrentChannel")
                .then(res => {
                    if (res.status === 200) {
                        axios.get("https://vzakat.ru/backend/api/chat/getAuto").then(result=>{
                            if(result.status===200){
                                setAutomationEnabled((Boolean) (result.data))
                                setChannel(res.data);
                            }
                        })
                    }
                });
        }


        if (!botsFetch) {
            axios.get("https://vzakat.ru/backend/api/bots")
                .then(res => {
                    if (res.status === 200) {
                        setBots(res.data);
                        setBotsFetch(true)
                    }
                });
        }

        const socket = new SockJS('https://vzakat.ru/backend/ws');
        const stompClient = Stomp.over(socket);

        stompClient.connect({}, (frame) => {
            console.log('Connected: ' + frame);

            stompClient.subscribe('/topic/chat', (message) => {
                try {
                    const chatMessage = JSON.parse(message.body);
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { username: chatMessage.username, text: chatMessage.message, color: chatMessage.color }
                    ]);
                } catch (error) {
                    console.error('Error processing message:', error);
                }
            }, (error) => {
                console.error('Subscription error:', error);
            });
        });

        socket.onclose = (event) => {
            console.error('WebSocket connection closed:', event);
        };

        return () => {
            if (stompClient) {
                stompClient.disconnect(() => {
                    console.log('Disconnected');
                });
            }
            socket.close();
        };
    }, [channel, bots]);

    if (channel != null) {
        return (
            <div className="main-page">
                <Header />
                <div className="chat-container">
                    <div className="channel-info">
                        <span>Выбранный канал: {channel}</span>
                        <button className="change-button" onClick={handleChangeChannel}>Изменить</button>
                    </div>
                    <div className="automation-toggle">
                        <label>
                            <input 
                                type="checkbox" 
                                checked={automationEnabled} 
                                onChange={handleToggleAutomation} 
                            />
                            Включить автоматизацию
                        </label>
                        <select 
                            value={selectedBot} 
                            onChange={handleBotChange} 
                            disabled={automationEnabled}
                        >
                            <option value={0}>Все</option>
                            {bots.map(bot => (
                                (bot.botStatus === "ACTIVE")?
                                <option key={bot.id} value={bot.id}>
                                    {bot.username}
                                </option>
                                :null
                            ))}
                        </select>
                    </div>
                    <div className="chat-window">
                        {messages.map((msg, index) => (
                            <div key={index} className="chat-message">
                                <strong style={{color: `rgb(${msg.color.r},${msg.color.g},${msg.color.b})`}}>{msg.username}:</strong> {msg.text}
                            </div>
                        ))}
                    </div>
                    <div className="message-input">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Введите сообщение"
                        />
                        <button onClick={handleSendMessage}>Отправить</button>
                    </div>
                </div>
                <ChangeModal show={showModal} onClose={() => setShowModal(false)}>
                    <div>
                        <label>Название канала:</label>
                        <input
                            type="text"
                            value={newChannelName}
                            onChange={(e) => setNewChannelName(e.target.value)}
                        />
                        <button onClick={handleSaveChannel}>Сохранить</button>
                    </div>
                </ChangeModal>
            </div>
        );
    }

    return null;
};

export default MainPage;
